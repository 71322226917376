import React, { Component } from "react";
import { Link } from "react-router-dom";
import PrivacyNoticeModal from "../common/PrivacyNoticeModal";  // Import the PrivacyNoticeModal component

class FooterLinks extends Component {
  state = {
    socials: [{ icon: "mdi mdi-linkedin", link: "#" }],
    footerLinks: [{ linkTitle: "Privacy Notice", link: "" }],
    showPrivacyModal: false, // State to control the modal visibility
  };

  // Function to show the Privacy Notice modal
  handleShowPrivacyModal = () => {
    this.setState({ showPrivacyModal: true });
  };

  // Function to close the Privacy Notice modal
  handleClosePrivacyModal = () => {
    this.setState({ showPrivacyModal: false });
  };

  render() {
    const { socials, footerLinks, showPrivacyModal } = this.state;

    return (
      <React.Fragment>
        <div className="footer-alt" style={footerStyle}>
          <div style={containerStyle}>
            {/* Left: Privacy Notice Link */}
            <div style={leftStyle}>
              <ul style={listStyle}>
                {footerLinks.map((link, key) => (
                  <li key={key} style={listItemStyle}>
                    {/* Click the link to trigger the modal */}
                    <Link
                      to="#"
                      onClick={this.handleShowPrivacyModal} // Trigger modal on click
                      style={{ ...linkStyle, ...smallTextStyle }}
                    >
                      {link.linkTitle}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            {/* Center: Assure Claims 2024 */}
            <div style={centerStyle}>
              <p className="copy-rights mb-0" style={textColorStyle}>
                2024 © Assure Claims
              </p>
            </div>

            {/* Right: Social Icons */}
            <div style={rightStyle}>
              <ul style={listStyle}>
                {socials.map((social, key) => (
                  <li key={key} style={listItemStyle}>
                    <Link to={social.link} className="social-icon" style={textColorStyle}>
                      <i className={social.icon}></i>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {/* Privacy Notice Modal */}
        <PrivacyNoticeModal
          show={showPrivacyModal}              // Modal visibility control
          handleClose={this.handleClosePrivacyModal} // Function to close the modal
        />
      </React.Fragment>
    );
  }
}

export default FooterLinks;

// Styles

const footerStyle = {
  backgroundColor: "#141c26",
  padding: "10px 0",
};

const containerStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  maxWidth: "1200px",
  margin: "0 auto",
  padding: "0 20px",
};

const leftStyle = {
  flex: 1,
  textAlign: "left",
};

const centerStyle = {
  flex: 1,
  textAlign: "center",
};

const rightStyle = {
  flex: 1,
  textAlign: "right",
};

const listStyle = {
  listStyleType: "none",
  padding: 0,
  margin: 0,
};

const listItemStyle = {
  display: "inline-block",
  margin: "0 10px",
};

const linkStyle = {
  textDecoration: "none",
};

const textColorStyle = {
  color: "#98a0aa", // Set the text color to match all other text
};

const smallTextStyle = {
  fontSize: "12px", // Reduce the size for Cookies text
  color: "#98a0aa", // Same color as other text
};
