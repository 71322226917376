import React from "react";
import { Modal, Button } from "react-bootstrap"; // Assuming Bootstrap is used

const PrivacyNoticeModal = ({ show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Assure Claims - Privacy Notice</Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <h5>Effective Date: 01/09/2024</h5>
          <p>
            At <strong>Assure Claims</strong>, we are committed to protecting the privacy and confidentiality of our clients and website visitors. This Privacy Notice explains how we collect, use, disclose, and protect your personal data, in compliance with applicable data protection regulations, including the <strong>General Data Protection Regulation (GDPR)</strong>.
          </p>

          <h5>1. Information We Collect</h5>
          <p>We collect personal data that is necessary for providing our services and enhancing your experience on our website. The types of data we collect include:</p>
          <ul>
            <li><strong>Personal Identification Information</strong>: Name, email address, phone number, mailing address.</li>
            <li><strong>Professional Information</strong>: Job title, company name.</li>
            <li><strong>Technical Information</strong>: IP address, browser type, and operating system (for security and diagnostic purposes).</li>
          </ul>

          <h5>2. How We Use Your Data</h5>
          <p>We use your personal data for the following purposes:</p>
          <ul>
            <li><strong>Providing Services</strong>: To manage and fulfill any requests or services you engage with us for, such as claims handling, consultancy, or other services provided by Assure Claims.</li>
            <li><strong>Communication</strong>: To respond to your inquiries, requests, or complaints, and to provide updates on our services.</li>
            <li><strong>Legal Obligations</strong>: To comply with our legal obligations, including tax and regulatory requirements.</li>
            <li><strong>Security</strong>: To ensure the security of our website and protect against fraud or unauthorized access.</li>
            <li><strong>Improvement</strong>: To monitor website usage and improve the user experience.</li>
          </ul>

          <h5>3. Legal Basis for Processing</h5>
          <p>Under GDPR, we rely on the following legal bases for processing your personal data:</p>
          <ul>
            <li><strong>Contractual Necessity</strong>: Where processing is necessary for us to fulfill our contractual obligations to you.</li>
            <li><strong>Legitimate Interests</strong>: When processing is in our legitimate interest and is not overridden by your data protection rights (e.g., website security, business analytics).</li>
            <li><strong>Consent</strong>: For any non-essential activities, such as sending marketing communications, we will seek your consent.</li>
          </ul>

          <h5>4. Sharing Your Data</h5>
          <p>
            We may share your data with third-party service providers who assist us in operating our website or delivering services (e.g., IT support, hosting providers). Any third parties we work with are required to comply with strict confidentiality and data protection regulations. We do not sell, trade, or otherwise transfer your personal data to outside parties unless required by law.
          </p>

          <h5>5. Cookies and Tracking Technologies</h5>
          <p>At <strong>Assure Claims</strong>, we only use cookies that are strictly necessary for the operation of our website. These include:</p>
          <ul>
            <li><strong>Session Cookies</strong>: To keep you logged in as you navigate through our website.</li>
            <li><strong>Security Cookies</strong>: To ensure the security of our services and protect against fraudulent activities.</li>
            <li><strong>Preference Cookies</strong>: To remember your language settings and other preferences (if applicable).</li>
          </ul>
          <p>We do not use cookies for tracking, analytics, or advertising. Learn more about how to control cookies via your browser settings.</p>

          <h5>6. Data Retention</h5>
          <p>We will only retain your personal data for as long as necessary to fulfill the purposes outlined in this Privacy Notice or as required by law. When your data is no longer required, we will securely delete or anonymize it.</p>

          <h5>7. Your Data Protection Rights</h5>
          <p>Under GDPR, you have the following rights regarding your personal data:</p>
          <ul>
            <li><strong>Access</strong>: You have the right to request a copy of your personal data.</li>
            <li><strong>Rectification</strong>: You have the right to correct inaccurate or incomplete data.</li>
            <li><strong>Erasure</strong>: You have the right to request the deletion of your personal data when it is no longer needed for the purposes for which it was collected.</li>
            <li><strong>Restriction</strong>: You have the right to request the restriction of processing of your personal data.</li>
            <li><strong>Portability</strong>: You have the right to request that we transfer your personal data to another service provider.</li>
            <li><strong>Objection</strong>: You have the right to object to the processing of your personal data in certain circumstances.</li>
          </ul>

          <h5>8. Data Security</h5>
          <p>We take the security of your data seriously and have implemented appropriate technical and organizational measures to protect your personal data from unauthorized access, alteration, disclosure, or destruction.</p>

          <h5>9. Changes to This Privacy Notice</h5>
          <p>We may update this Privacy Notice from time to time to reflect changes in our practices or legal requirements. We encourage you to review this page periodically for any updates.</p>

          <h5>10. Contact Us</h5>
          <p>If you have any questions or concerns about this Privacy Notice or your data protection rights, please contact us at:</p>
          <p><strong>Assure Claims</strong><br />
            Email: <a href="mailto:info@assureclaims.com">info@assureclaims.com</a>
          </p>
        </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PrivacyNoticeModal;
