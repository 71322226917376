import React from 'react';

const GoogleSheetEmbed = () => {
  // Your Google Sheet URL (editable version)
  const googleSheetUrl = 'https://docs.google.com/spreadsheets/d/1FLsTo63mIDG5eODxwQlG_O6_ewggcSluc9BkfDmWgBg/edit?usp=sharing';

  return (
    <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <iframe
        src={googleSheetUrl}
        style={{ width: '100%', height: '100%', border: '0' }}
        title="Google Sheet"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default GoogleSheetEmbed;
