import React, { Component } from "react";
import { Link } from "react-router-dom";

// Import Footer link
import FooterLinks from "./footer-links";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSwitchToggle: false,
    };
    this.toggleSwitcher = this.toggleSwitcher.bind(this);
    this.setTheme = this.setTheme.bind(this);
  }

  // Always set the theme to blue
  componentDidMount() {
    this.setTheme("blue");
  }

  setTheme = (color) => {
    document.getElementById("colorTheme").href =
      "assets/colors/" + color + ".css";
  };

  // Remove the dark mode toggle
  toggleSwitcher = () => {
    this.setState({ isSwitchToggle: !this.state.isSwitchToggle });
  };

  render() {
    return (
      <React.Fragment>
        <footer className="footer" style={footerStyle}>
          <FooterLinks />
        </footer>

        {/* Style Switcher */}
        <div
          id="style-switcher"
          className={this.state.isSwitchToggle ? "show" : " "}
          style={{ left: this.state.isSwitchToggle ? "0px" : "-189px",
          }}
        >
          {/* Removed color selection options */}
          {/* Removed dark mode toggle */}
          <Link
            to="#"
            onClick={this.toggleSwitcher}
            className="settings rounded-right"
          >
            <i className="mdi mdi-cog mdi-spin"></i>
          </Link>
        </div>
      </React.Fragment>
    );
  }
}

export default Footer;

// Apply custom footer style
const footerStyle = {
  backgroundColor: 'transparent', // Remove the black box (set to transparent or remove this line)
  padding: '0', // Remove any unwanted padding
  margin: '0', // Ensure no unwanted margins
};
