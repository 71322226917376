import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

class WebsiteDescription extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section bg-web-desc">
          <div className="bg-overlay"></div>
          <Container>
            <Row>
              <Col lg="12" className="text-center">
                <h2 className="text-white">Built with Fault and Non-Fault Claims At The Core</h2>
                <p className="pt-3 home-desc mx-auto">
                  Assure Claims was founded on the basis that fault and non-fault claims need to share priorty
                  in order to provide a complete claims solution and market leading indemity results.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default WebsiteDescription;
