import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Service Box
import ServiceBox from "./service-box";

class Services extends Component {
  state = {
    services: [
      {
        title: "Indemnity Savings",
        icon: "pe-7s-timer",
        description:
          "We will analyse claims data to identify trends in payout reductions and leverage our financial software to report on savings realised through intervention, negotiated settlements and alternative dispute resolutions.",
      },
      {
        title: "Strategic Partnerships",
        icon: "pe-7s-display2",
        description:
          "Collaborating with insurers and technology firms can lead to innovative solutions and enhanced service offerings.",
      },
      {
        title: "Data Analytics Tools",
        icon: "pe-7s-piggy",
        description:
          "By implementing robust data analytics tools, we can gain insights into claim trends, identify potential areas for improvement, and optimize our claims handling strategy continuously.",
      },
      {
        title: "AI Partnership",
        icon: "pe-7s-science",
        description:
          "We have colloborated with AnnieAI to bring the latest machine learning models to our claims processes.",
      },
      {
        title: "Risk Management",
        icon: "pe-7s-news-paper",
        description:
          "We continuously assess our operations to identify and evaluate potential risks associated with claims handling and regulatory compliance.",
      },
      {
        title: "Customer Service",
        icon: "pe-7s-plane",
        description:
          "Our customer service philosophy is built on three foundational pillars: responsiveness, expertise, and trust.",
      },
      {
        title: "Communication",
        icon: "pe-7s-arc",
        description:
          "We recognise that clear and transparent communication is essential to fostering strong relationships with our clients.",
      },
      {
        title: "Reporting",
        icon: "pe-7s-tools",
        description:
          "Our approach will include regular and scheduled reports to ensure transparency and clarity in all operations. This commitment to data- driven insights will facilitate informed decision-making and foster trust.",
      },
      {
        title: "Targeted Solutions",
        icon: "pe-7s-diamond",
        description:
          "By focusing on specific markets claims handling services, Assure Claims can differentiate itself from competitors and provide greater value.",
      },
    ],
  };
  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" id="services">
          <Container>
            {/* Render section title */}
            <SectionTitle
              title="Our Services"
              description="Assure Claims offers a comprehensive claims handling services designed to meet the needs of our clients. Our offering is strategically developed to ensure efficiency, accuracy, and cost-effectiveness, reinforcing our commitment to safeguarding our clients' interests."
            />

            <Row className="mt-5">
              {/* render service box */}
              {this.state.services.map((service, key) => (
                <ServiceBox
                  key={key}
                  title={service.title}
                  icon={service.icon}
                  description={service.description}
                />
              ))}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Services;
