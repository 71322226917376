import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

class Contact extends Component {
    render() {
        return (
            <React.Fragment>
        <section className="section section-lg bg-get-start" id="contact">
            <div className="bg-overlay"></div>
            <Container>
                <Row>
                    <Col lg={{size :8, offset:2}} className="text-center">
                        <h1 className="get-started-title text-white">Let's Get Started</h1>
                        <div className="section-title-border mt-4 bg-white"></div>
                        <p className="section-subtitle font-secondary text-white text-center pt-4">Please get in touch and drop us a message and we will be happy to discuss a solution for your requirements. </p>
                        <Link to="#" className="btn btn-light waves-effect mt-4">Call Us Now</Link>
                    </Col>
                </Row>
            </Container>
        </section>
            </React.Fragment>
        );
    }
}

export default Contact;