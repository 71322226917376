import React, { Component } from "react";
import {
  Nav,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Container,
  Collapse,
} from "reactstrap";
import ScrollspyNav from "./scrollSpy";

// Import Sticky Header
import StickyHeader from "react-sticky-header";
import "../../../node_modules/react-sticky-header/styles.css";

class Navbar_Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: [
        { id: 1, idnm: "home", navheading: "Home" },
        { id: 2, idnm: "summary", navheading: "Summary" },
        { id: 3, idnm: "services", navheading: "Services" },
        { id: 4, idnm: "about", navheading: "About" },
        { id: 5, idnm: "contact", navheading: "Contact" },
      ],
      isOpenMenu: false,
    };
  }

  toggle = () => {
    this.setState({ isOpenMenu: !this.state.isOpenMenu });
  };

  render() {
    /********************* Menu Js **********************/

    function windowScroll() {
      const navbar = document.getElementById("navbar");
      if (
        document.body.scrollTop >= 50 ||
        document.documentElement.scrollTop >= 50
      ) {
        navbar.classList.add("nav-sticky");
      } else {
        navbar.classList.remove("nav-sticky");
      }
    }

    window.addEventListener("scroll", (ev) => {
      ev.preventDefault();
      windowScroll();
    });

    // Store all Navigationbar Id into TargetID variable(Used for Scrollspy)
    let TargetId = this.state.navItems.map((item) => {
      return item.idnm;
    });

    return (
      <React.Fragment>
        <StickyHeader
          header={
            <div
              className={
                this.props.navClass +
                " navbar navbar-expand-lg fixed-top  navbar-custom sticky sticky-dark"
              }
              id="navbar"
            >
              <Container>
                <NavbarBrand className="logo text-uppercase custom-logo" href="/">
                  <img src={require('../../logo.png')} alt="Logo" height="20" />
                </NavbarBrand>

                <NavbarToggler
                  className=""
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarCollapse"
                  aria-controls="navbarCollapse"
                  aria-expanded="false"
                  onClick={this.toggle}
                >
                  <i className="mdi mdi-menu"></i>
                </NavbarToggler>

                <Collapse
                  id="navbarCollapse"
                  isOpen={this.state.isOpenMenu}
                  navbar
                >
                  <div style={{ display: 'flex', justifyContent: 'flex-end', width: '90%' }}>
                    <ScrollspyNav
                      scrollTargetIds={TargetId}
                      activeNavClass="active"
                      scrollDuration="800"
                      headerBackground="true"
                    >
                      <Nav className="navbar-nav" id="mySidenav">
                        {this.state.navItems.map((item, key) => (
                          <NavItem
                            key={key}
                            className={item.navheading === "Home" ? "active" : ""}
                          >
                            <NavLink href={"#" + item.idnm}>
                              {item.navheading}
                            </NavLink>
                          </NavItem>
                        ))}
                      </Nav>
                    </ScrollspyNav>
                  </div>
                </Collapse>
              </Container>
            </div>
          }
          stickyOffset={-100}
        ></StickyHeader>
      </React.Fragment>
    );
  }
}

export default Navbar_Page;
