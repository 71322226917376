import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";


class Section extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.callModal.bind(this);
  }

  callModal = () => {
    this.refs.child.openModal();
  };

  render() {
    return (
      <React.Fragment>
        <section className="section bg-home home-half" id="home" data-image-src="images/bg-home.jpg"> 
          <div className="bg-overlay"></div>
          <Container>
            <Row>
              <Col
                lg={{ size: 8, offset: 2 }}
                className="text-white text-center"
              >
                <h1 className="home-title">
                  Mastering Claims With Precision
                </h1>
                <p className="pt-3 home-desc mx-auto">
                  Focusing on delivering efficent, reliable and holististic claims handling solutions for both fault and non-fault claims. 
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
