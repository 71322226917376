import React from "react";
import { Container, Row, Col } from "reactstrap";

const AboutUs = () => {
  return (
    <section className="section" id="about">
      <Container>
        <Row>
          <Col lg={{ size: 8, offset: 2 }}>
            <div className="about-title mx-auto text-center">
              <h2 className="font-weight-light">
                The Board & Our Advisors
              </h2>
              <p className="text-muted pt-4">
                Assure Claims was created from a need for a full claims solution that would reduce leakage by handling non-fault and fault claims to the highest level. 
                With this in mind we created a team with skillsets in underwritting, broking, MGA's, full claims handling, FNOL, non-fault claims and credit hire. 

                We are uniquley position with over 100 years of experience in the above fields across our 5 board members and advisors. 
              </p>
            </div>
          </Col>
        </Row>
        <Row className="mt-5">
          {/* You can add more content here */}
        </Row>
      </Container>
    </section>
  );
};

export default AboutUs;
