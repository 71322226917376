import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

class Summary extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section" id="summary">
          <Container>
            <Row className="align-items-center">
              <Col lg="5" className="order-2 order-lg-1">
                <div className="features-box mt-5 mt-lg-0">
                  <h3>
                    Assure Claims offers a complete suite of claims handling services.
                  </h3>
                  <p className="text-muted web-desc">
                    Primarily, but not solely, targeted at established MGA's, 
                    MGU's, Underwriters & Brokers in the UK.
                  </p>
                  <ul className="text-muted list-unstyled mt-4 features-item-list">
                    <li className="">Comprehensive claims managmement for fault & non-fault claims.</li>
                    <li className="">
                      Indemnity spending focus & analysis.
                    </li>
                    <li className="">Expert guidance throughout the claim proccess.</li>
                    <li className="">Extensive knowledge to provide fast decisions to reduce indemnity spend.</li>
                  </ul>
                </div>
              </Col>
              <Col lg={{ size: 7, order: 2 }} xs={{ order: 1 }}>
                <div className="features-img mx-auto me-lg-0">
                  <img
                    src="assets/images/growth-analytics.svg"
                    alt="Office"
                    className="img-fluid"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Summary;
